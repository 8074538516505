import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Icons } from "../global/icon";
import PropTypes from "prop-types";

function ViewsUIWrapper({ title, children, className }) {
  const location = useLocation();
  const staticURL = location.pathname.replace("views", "static");
  const currentPageUrlArr = location.pathname.split("/");
  const isBlog = currentPageUrlArr.includes("blogs");

  const currentPage = currentPageUrlArr[currentPageUrlArr.length - 1];

  currentPageUrlArr[4] = "blog";
  const blogViewURL = currentPageUrlArr.join("/");

  return (
    <section className={`w-full ${className}`}>
      <h1 className="text-xl md:text-xl font-semibold bg-white fixed md:left-[53%] md:text-center mt-2  top-[120px] z-10">
        {title}
      </h1>
      <main>{children}</main>

      {/* Link to static page */}
      <div className="fixed md:w-fit h-fit inset-x-7 md:inset-x-auto md:right-[10%] top-auto bottom-5 md:bottom-auto md:top-[14.5%] z-30 flex gap-5 justify-around bg-white py-3 px-3 md:p-0">
        <Link
          to={isBlog ? blogViewURL : staticURL}
          className="italic self-center flex gap-2"
        >
          <span className="text-sm md:text-base self-center">
            {isBlog
              ? `Edit Blog`
              : `Edit ${currentPage
                  .slice(0, 1)
                  .toUpperCase()}${currentPage.slice(1)}`}
          </span>
          <Icons.ArrowRight
            className="w-5 h-5 md:w-7 md:h-6"
            strokeWidth={1.2}
          />
        </Link>
      </div>
    </section>
  );
}

ViewsUIWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default ViewsUIWrapper;
