import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { serviceSlice } from "../../../../redux/slice/service-slice";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import PropTypes from "prop-types";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

function HeroSection({ heroData }) {
  const newKeys = ["Title", "Body", "Paragraph"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([service, serviceIdx]) => {
          return (
            <div key={service} className="flex flex-col gap-3 font-semibold">
              <h3>{service}</h3>
              <ViewsCard content={serviceIdx} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

function ServicesSection({ servicesData }) {
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content={`Services Section`} />
      {servicesData.map((service, serviceIdx) => {
        return (
          <div
            key={serviceIdx}
            className="flex flex-col gap-4 items-center w-full"
          >
            {/* Stacks Header */}
            <SectionHeading content={`Service ${serviceIdx + 1}`} />
            <div className="flex flex-col md:flex-row gap-5 flex-wrap items-center md:items-start w-full">
              <ViewsCard label="Title" content={service.title} />
              <ViewsCard label="Body" content={service.body} />
              {/* Stacks */}
              <div className="space-y-2 col-span-3">
                <h3 className="text-center border-b border-grey-700 pb-3 font-medium">
                  Stacks
                </h3>
                <div className="col-span-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 w-full justify-items-center md:justify-self-start">
                  {service.stacks.map((stack, stackIdx) => {
                    return <ViewsCard key={stackIdx} content={stack} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Section>
  );
}

ServicesSection.propTypes = {
  servicesData: PropTypes.array.isRequired,
};

function AboutSection({ aboutData }) {
  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-center md:justify-items-start gap-4">
        {aboutData.map((info, infoIdx) => {
          return (
            <div key={infoIdx} className="flex flex-col gap-3">
              <SectionHeading
                content={`About ${infoIdx + 1}`}
                className="!self-start text-[15px]"
              />
              <div className="flex gap-2 flex-col items-center md:flex-row md:items-start">
                <ViewsCard content={info.body} />
                <ViewsCard content={info.paragraph} />
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}
AboutSection.propTypes = {
  aboutData: PropTypes.array.isRequired,
};

function TalkToUsSection2({ sectionData }) {
  const newKeys = ["Title", "Contact Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
TalkToUsSection2.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

// Main component
function ServiceV() {
  const loading = useSelector((state) => state.servicePage.loading);
  const error = useSelector((state) => state.servicePage.error);
  const serviceData = useSelector((state) => state.servicePage.data);

  const { makeRequest: getServiceData } = useRequest("GET", {}, serviceSlice);

  useEffect(() => {
    const getData = async () => {
      const [statusCode, response] = await getServiceData("/service");

      handleResponse(
        response,
        statusCode,
        error,
        serviceData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !serviceData) return <DashLoader message={"Loading"} />;

  return (
    <ViewsUIWrapper title={"Services Page"}>
      {serviceData && (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={serviceData?.hero} />
          <ServicesSection servicesData={serviceData?.services} />
          <AboutSection aboutData={serviceData?.about} />
          <TalkToUsSection2 sectionData={serviceData?.section} />
        </div>
      )}
    </ViewsUIWrapper>
  );
}

export default ServiceV;
