import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { partnerSlice } from "../../../../redux/slice/partner-slice";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import PropTypes from "prop-types";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

function HeroSection({ heroData }) {
  const newKeys = ["Title", "Body", "Paragraph", "Button"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

function BenefitSection({ benefitData }) {
  const newKeys = ["Title", "Benefits"];

  const newBenefitData = replaceObjectKeys(benefitData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Benefits Section" />
      <div className="flex flex-col items-center md:items-start gap-4">
        {Object.entries(newBenefitData).map(([element, desc], idx) => {
          if (element === "Title") {
            return <ViewsCard key={idx} content={desc} label={element} />;
          } else {
            return (
              <div key={idx} className="space-y-5">
                <h3 className="text-center font-bold">{element}</h3>
                {/* Benefits */}
                <div className="flex flex-shrink-0 gap-4 flex-wrap">
                  {desc.map((benefit, benefitIdx) => {
                    return (
                      <div key={benefitIdx} className="space-y-3">
                        <h3 className="text-center font-semibold">{`Benefit ${
                          benefitIdx + 1
                        }`}</h3>
                        <div className="flex gap-3 font-semibold flex-wrap md:flex-nowrap justify-center md:justify-start">
                          <ViewsCard
                            content={benefit.title}
                            label={"Title"}
                            labelStyle="text-sm"
                          />
                          <ViewsCard
                            content={benefit.body}
                            label={"Body"}
                            labelStyle="text-sm"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
        })}
      </div>
    </Section>
  );
}

BenefitSection.propTypes = {
  benefitData: PropTypes.object.isRequired,
};

function CommunitySection({ communityData }) {
  const newKeys = ["Title", "Button"];

  const newSectionData = replaceObjectKeys(communityData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Community Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
CommunitySection.propTypes = {
  communityData: PropTypes.object.isRequired,
};

function GetInTouchSection({ sectionData }) {
  const newKeys = ["Title", "Contact Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Get In Touch Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
GetInTouchSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

function PartnersV() {
  const loading = useSelector((state) => state.partnerPage.loading);
  const error = useSelector((state) => state.partnerPage.error);
  const partnerData = useSelector((state) => state.partnerPage.data);

  const { makeRequest: getPartnerData } = useRequest("GET", {}, partnerSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getPartnerData("/partner");

      handleResponse(
        response,
        statusCode,
        error,
        partnerData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !partnerData) return <DashLoader message="Loading..." />;
  return (
    <ViewsUIWrapper title={"Partners Page"}>
      {partnerData && (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={partnerData?.hero} />
          <BenefitSection benefitData={partnerData?.benefit} />
          <CommunitySection communityData={partnerData?.community} />
          <GetInTouchSection sectionData={partnerData?.section} />
        </div>
      )}
    </ViewsUIWrapper>
  );
}

export default PartnersV;
