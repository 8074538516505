import React from "react";
import GeneralButton from "../global/general-button";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Icons } from "../global/icon";

function FormWrapper({ title, children, onSubmit, loading, className }) {
  const location = useLocation();
  const viewsURL = location.pathname.replace("static", "views");
  const currentPageUrlArr = location.pathname.split("/");
  const currentPage = currentPageUrlArr[currentPageUrlArr.length - 1];
  currentPageUrlArr[4] = "view";
  const blogViewURL = currentPageUrlArr.join("/");

  return (
    <form onSubmit={onSubmit} className={`w-full ${className}`}>
      <h1 className="text-xl md:text-xl font-semibold bg-white fixed md:left-[53%] md:text-center mt-2  top-[120px] z-10">
        {title}
      </h1>
      <div className="h-full space-y-8">{children}</div>
      {/* Update button */}
      <div className="fixed lg:w-fit h-fit inset-x-7 lg:inset-x-auto lg:right-[10%] top-auto bottom-5 lg:bottom-auto lg:top-[13%] z-30 flex gap-5 justify-around md:justify-center lg:justify-around bg-white py-3 px-3 md:p-0">
        <GeneralButton content="Update" isLoading={loading} />
        <Link
          to={currentPage === "blog" ? blogViewURL : viewsURL}
          className="italic self-center flex gap-2"
        >
          <span className="text-sm md:text-base self-center">
            {`${currentPage.slice(0, 1).toUpperCase()}${currentPage.slice(1)}`}{" "}
            views
          </span>
          <Icons.ArrowRight
            className="w-5 h-5 md:w-7 md:h-6"
            strokeWidth={1.2}
          />
        </Link>
      </div>
    </form>
  );
}

FormWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default FormWrapper;
