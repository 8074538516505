import React, { useEffect, useRef } from "react";
import { Icons } from "../global/icon";
import { useSelector } from "react-redux";
import { Link, useLoaderData, useLocation } from "react-router-dom";

function BlogsCardOptions({
  className,
  id,
  isPublished,
  action,
  setAction,
  isOptionOpen,
  handleOptionsOpenAndClose,
  handlePublish,
}) {
  const loading = useSelector((state) => state.blogPage?.loading);

  const location = useLocation();
  const staticURL = location.pathname.replace("view", "blog");
  const currentPageUrlArr = location.pathname.split("/");
  const currentPage = currentPageUrlArr[currentPageUrlArr.length - 1];
  const optionsRef = useRef(null);

  // Close options when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        handleOptionsOpenAndClose(false); // Close options menu
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleOptionsOpenAndClose]);

  return (
    <div className={`absolute right-4 bottom-8 ${className}`}>
      {/* // <div className={`self-start ${className}`}> */}
      {loading ? (
        <div>{action}</div>
      ) : (
        <div className="relative">
          <button
            className="w-fit h-fit rounded-lg px-4 py-0.5"
            onClick={() => handleOptionsOpenAndClose(true)}
          >
            <Icons.VerticalDots className="w-5" />
          </button>
          {isOptionOpen && (
            <div
              ref={optionsRef} // Add ref to options menu div
              className="absolute bottom-full right-full bg-white z-30 flex flex-col gap-1 border border-slate-300 rounded-md"
            >
              {/* Cancel button */}
              <button
                className="w-fit h-fit self-end mt-1 mr-1 p-0"
                onClick={() => handleOptionsOpenAndClose(false)}
              >
                <Icons.Cancel className="w-5 h-5" strokeWidth={2} />
              </button>
              {/* Options */}
              <div className="">
                {["Publish", "Delete", "Edit"].map((option, idx) => {
                  return (
                    <div key={idx}>
                      {option === "Edit" ? (
                        <p
                          className={`border-b border-b-gray-200 py-2 px-6 cursor-pointer`}
                        >
                          <Link to={staticURL}>{option}</Link>
                        </p>
                      ) : option === "Publish" ? (
                        <p
                          onClick={handlePublish}
                          className={`border-y border-y-gray-200 py-2 px-6 cursor-pointer`}
                        >
                          {option}
                        </p>
                      ) : (
                        <p
                          className={`border-b border-b-gray-200 py-2 px-6 cursor-pointer`}
                        >
                          {option}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BlogsCardOptions;
