import React, { useState } from "react";
import { useForm } from "react-hook-form";
import EyeToggle from "../../components/global/eye-toggle";
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../../components/global/icon";
import useRequest from "../../components/hook/use-request";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userSlice } from "../../redux/slice/user-slice";
import Logo from "../../components/global/logo";
import GeneralButton from "../../components/global/general-button";

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const navigate = useNavigate();
  const { register, handleSubmit, formState, reset } = useForm();
  const { errors, isSubmitting } = formState;
  const { makeRequest: loginUser } = useRequest("POST", {}, userSlice);

  const handleLogin = async (formData) => {
    const admin = {
      email: formData.email,
      password: formData.password,
    };

    const [userDetails, statusCode, response] = await loginUser(
      "/users/login",
      admin
    );

    if (response?.ok && statusCode === 200) {
      localStorage.setItem("token", userDetails.token);
      toast.success("Login successful! Redirecting to dashboard...");
      reset();
      navigate("/dashboard");
    }
    if (!response?.ok && statusCode === 400) {
      toast.error(`Unsuccessful: Bad request`);
      return;
    }
    if (!response?.ok && statusCode === 401) {
      toast.error(`Invalid credentials..`);
      return;
    }
    if (!response?.ok && statusCode === 500) {
      toast.error(`Internal Server Error...Please try again`);
      return;
    }
    if (error && statusCode === 0 && userDetails === null) {
      toast.error(error);
      return;
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="flex overflow-hidden h-screen">
      <div className="hidden md:block lg:w-[50vw] md:h-screen">
        <img
          src="/images/cms_image.webp"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>

      <main className="flex justify-center items-center px-10 lg:px-0 w-full lg:w-[50vw] h-full">
        <div className="flex flex-col gap-6 w-full lg:w-[25rem] max-w-[20rem] lg:max-w-[25rem]">
          <Logo className={"self-center"} />
          <div className="flex flex-col gap-1 items-center">
            <h1 className="font-semibold text-xl lg:text-2xl">Welcome back!</h1>
            <p className="flex gap-1 text-xs">
              <span>Don't have an account?</span>
              <Link to="/auth/register" className="underline font-medium">
                Register
              </Link>
            </p>
          </div>

          <form
            onSubmit={handleSubmit(handleLogin)}
            className="flex flex-col gap-7"
          >
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="email" className="text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    // pattern: {
                    //   value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    //   message: "Invalid email format",
                    // },
                  })}
                  placeholder="johndoe@gmail.com"
                  id="email"
                  className="border border-slate-400 px-4 py-2 focus:outline-none focus:border-green-300 rounded-md"
                />
                {errors.email && (
                  <p className="text-red-700 text-xs">
                    {errors.email?.message}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="password" className="text-sm font-medium">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                    })}
                    placeholder="Type in a strong password"
                    id="password"
                    className="border border-slate-400 pl-4 pr-12 py-2 focus:outline-none w-full focus:border-green-300 rounded-md"
                  />
                  <EyeToggle
                    content={
                      passwordVisible ? (
                        <Icons.EyeOff
                          strokeWidth={1.3}
                          className="text-slate-500"
                        />
                      ) : (
                        <Icons.Eye
                          strokeWidth={1.4}
                          className="text-slate-600"
                        />
                      )
                    }
                    className="absolute top-2 right-3"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                {errors.password && (
                  <p className="text-red-700 text-xs">
                    {errors.password?.message}
                  </p>
                )}
              </div>

              <div className="flex text-xs justify-between flex-wrap">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    {...register("remember_password")}
                    id="remember_password"
                    className="self-center accent-green-400"
                  />
                  <label htmlFor="remember_password">Remember me</label>
                </div>
                <Link
                  to="/auth/reset-password"
                  className="underline font-medium"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <GeneralButton
              content="Login"
              className="w-full"
              isLoading={loading || isSubmitting}
            />
          </form>
          <Link className="flex gap-2 self-center text-sm" to={"/"}>
            <Icons.ArrowLeft width={18} height={18} className="self-center" />
            <span>Back home</span>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Login;
