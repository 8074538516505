import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { projectSlice } from "../../../../redux/slice/project-slice";
import { toast } from "react-toastify";
import DashLoader from "../../../../components/global/dash-loader";
import PropTypes from "prop-types";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

function ProjectSection({ projectSectionData }) {
  const filteredData = {
    body: projectSectionData?.body,
    paragraph: projectSectionData?.paragraph,
    projects: projectSectionData?.projects,
  };

  const newKeys = ["Body", "Paragraph", "Projects"];

  const newProjectData = replaceObjectKeys(filteredData, newKeys);

  return (
    <Section className="flex flex-col md:flex-row gap-8 flex-wrap items-center md:items-start">
      <ViewsCard label="Body" content={newProjectData.Body} />
      <ViewsCard label="Paragraph" content={newProjectData.Paragraph} />
      {/* Projects */}
      <div className="flex flex-col gap-3 w-full">
        {/* Projects Header */}
        {/* Each Project */}
        {newProjectData.Projects.map((project, projectIdx) => {
          return (
            <div
              key={projectIdx}
              className="flex flex-col gap-5 border-b border-gray-500 pb-3 font-semibold col-span-3 w-full"
            >
              <div className="flex flex-col gap-2">
                {/* Project subheading */}
                <SectionHeading
                  content={`Project ${projectIdx + 1}`}
                  className="text-[15px]"
                />
                <div className="w-full space-y-4">
                  {/* Overview */}
                  {project.overview && (
                    <div className="space-y-2">
                      <h3 className="text-center border-b border-grey-700 pb-3">
                        Overview
                      </h3>
                      <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full justify-items-center md:justify-items-start">
                        <ViewsCard
                          label="Client"
                          content={project.overview.client}
                        />
                        <ViewsCard
                          label="Service"
                          content={project.overview.service}
                        />
                        <ViewsCard
                          label="Duration"
                          content={project.overview.duration}
                        />
                        <ViewsCard
                          label="Deliverable"
                          content={project.overview.deliverable}
                        />
                      </div>
                    </div>
                  )}

                  {/* Summary One */}
                  {project.summaryOne && (
                    <div>
                      <h3 className="text-center border-b border-grey-700 pb-3">
                        Summary One
                      </h3>
                      <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full justify-items-center md:justify-items-start">
                        <ViewsCard
                          label="Paragraph"
                          content={project.summaryOne.paragraph}
                        />
                        <ViewsCard
                          label="Image"
                          content={project.summaryOne.image}
                        />

                        {project.summaryOne.lists.map((list, listIdx) => (
                          <ViewsCard
                            key={listIdx}
                            label={`Item ${listIdx + 1}`}
                            content={list.items}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Summary Two */}
                  {project.summaryTwo && (
                    <div>
                      <h3 className="text-center border-b border-grey-700 pb-3">
                        Summary Two
                      </h3>
                      <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full justify-items-center md:justify-items-start">
                        <ViewsCard
                          label="Paragraph"
                          content={project.summaryTwo.paragraph}
                        />
                        <ViewsCard
                          label="Image"
                          content={project.summaryTwo.image}
                        />
                        {project.summaryTwo.lists.map((list, listIdx) => (
                          <ViewsCard
                            key={listIdx}
                            label={`Item ${listIdx + 1}`}
                            content={list.items}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Keywords */}
                  {project.keywords && project.keywords.length > 0 && (
                    <div className="space-y-2">
                      <h3 className="text-center border-b border-grey-700 pb-3">
                        Keywords
                      </h3>
                      <div className="grid gap-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full justify-items-center md:justify-items-start">
                        {project.keywords.map((keyword, keywordIdx) => {
                          return (
                            <ViewsCard key={keywordIdx} content={keyword} />
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* Rendering Reviews */}
                  {project.reviews && project.reviews.length > 0 && (
                    <div className="space-y-2 col-span-3">
                      <h3 className="text-center border-b border-grey-700 pb-3">
                        Reviews
                      </h3>
                      <div className="space-y-2">
                        {project.reviews.map((review, reviewIdx) => {
                          return (
                            // Each reviewer
                            <div key={reviewIdx} className="space-y-2">
                              <h4 className="text-center">{`Review ${
                                reviewIdx + 1
                              }`}</h4>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 w-full justify-items-center md:justify-items-start">
                                <ViewsCard
                                  labelStyle="text-sm !font-normal"
                                  label="Name"
                                  content={review.name}
                                />
                                <ViewsCard
                                  label="Comment"
                                  labelStyle="text-sm !font-normal"
                                  content={review.comment}
                                />
                                <ViewsCard
                                  label="Image"
                                  labelStyle="text-sm !font-normal"
                                  content={
                                    <img
                                      src={review.image}
                                      alt={`Review ${reviewIdx}`}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

ProjectSection.propTypes = {
  projectSectionData: PropTypes.object.isRequired,
};

const ProjectV = () => {
  const loading = useSelector((state) => state.projectPage.loading);
  const error = useSelector((state) => state.projectPage.error);
  const projectData = useSelector((state) => state.projectPage.data);

  const { makeRequest: getProjectData } = useRequest("GET", {}, projectSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getProjectData("/project");
      if (response?.ok && statusCode === 200) {
        toast.success("Successfully fetched");

        return;
      }
      if (!response?.ok && statusCode === 400) {
        toast.error(`Unsuccessful: Bad request`);
        return;
      }
      if (!response?.ok && statusCode === 401) {
        toast.error(`Unauthorized...`);
        return;
      }
      if (!response?.ok && statusCode === 500) {
        toast.error(`Internal Server Error...Please try again`);
        return;
      }
      if (error && statusCode === 0 && projectData === null) {
        toast.error(error);
        return;
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !projectData) return <DashLoader message="Loading..." />;
  return (
    <ViewsUIWrapper title={"Project Page"}>
      {projectData && <ProjectSection projectSectionData={projectData} />}
    </ViewsUIWrapper>
  );
};

export default ProjectV;
