import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import {
  blogFieldArr,
  blogTemplates,
} from "../../../../utils/dashboardFormData/blog/blogPageData";
import InputComponent from "../../../../components/dashboard/inputComponent";
import Label from "../../../../components/dashboard/label";
import InputField from "../../../../components/dashboard/inputField";
import AddButton from "../../../../components/dashboard/addButton";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { blogSlice } from "../../../../redux/slice/blog-slice";
import { transformApiData } from "../../../../utils/transformData";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import { blogSectionMap } from "../../../../utils/sectionMapData";
import RemoveButton from "../../../../components/dashboard/removeButton";
import FormWrapper from "../../../../components/dashboard/formWrapper";

function BlogPage() {
  const [response, setResponse] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [currentBlog, setCurrentBlog] = useState(null);

  const methods = useForm({
    defaultValues: {
      ...blogTemplates,
    },
  });

  const { register, control, watch } = methods;

  const {
    fields: blogFields,
    append: appendBlog,
    remove: removeBlog,
  } = useFieldArray({
    control,
    name: "blogs",
  });

  const loading = useSelector((state) => state.blogPage.loading);
  const error = useSelector((state) => state.blogPage.error);
  const blogData = useSelector((state) => state.blogPage.data);

  const isInitialized = useRef(false);

  const { makeRequest: getBlogData } = useRequest("GET", {}, blogSlice);
  const { makeRequest: createBlogData } = useRequest("POST", {}, blogSlice);
  const { makeRequest: updateBlogData } = useRequest("PUT", {}, blogSlice);

  // GET FORM DATA
  useEffect(() => {
    const getData = async () => {
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getBlogData("/blog");
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
      handleResponse(
        response,
        statusCode,
        error,
        blogData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!blogData) {
      const transformedData = transformApiData(blogData, blogSectionMap);
      if (transformedData && Object.keys(transformedData).length > 0) {
        // Populate the form with fetched data
        methods.reset(transformedData);
        isInitialized.current = true;
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogData]);

  // WATCH FOR VALUES TO UPDATE
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type === "change" && name?.startsWith("blogs")) {
        const index = parseInt(name.split("[")[1].split("]")[0], 10);
        // Blog to update
        const blogToUpdate = values.blogs[index];
        setCurrentBlog(blogToUpdate);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  // UPDATE FUNCTION
  const updateBlog = async () => {
    if (currentBlog) {
      const blogId = currentBlog?.id;
      setLoadingMessage("Updating data...");
      delete currentBlog?.id;
      // Your update logic here
      const [updatedBlog, updatedStatusCode, updatedResponse] =
        await updateBlogData(`/blog/${blogId}`, currentBlog);
      handleResponse(
        updatedResponse,
        updatedStatusCode,
        error,
        updatedBlog,
        "Blog updated successfully!"
      );

      // Refetch the updated data
      setLoadingMessage("Getting data...");
      const [, initialStatusCode, initialResponse] = await getBlogData("/blog");
      setResponse(initialResponse);
      setStatusCode(initialStatusCode);
    }
  };

  // NEW DATA TO CREATE
  const newBlogData = {
    title: "",
    url: "",
    author: "",
    readTime: "",
    image: "",
  };

  // CREATE NEW DATA
  async function handleCreate(newData) {
    const [newBlog, updateStatusCode, updatedResponse] = await createBlogData(
      "/blog",
      newData
    );
    // Handle status code for update
    setStatusCode(updateStatusCode);

    // Display toast
    handleResponse(
      updatedResponse,
      statusCode,
      error,
      newBlog,
      "Blog created successfully!"
    );

    // Append new field to field array
    appendBlog({
      title: "",
      url: "",
      author: "",
      readTime: "",
      image: "",
      id: newBlog?._id,
    });

    // Fetch latest data (including the updated one)
    setLoadingMessage("Getting data...");
    const [, getStatusCode, getResponse] = await getBlogData("/blog");
    setResponse(getResponse);
    setStatusCode(getStatusCode);
  }

  if (loading) {
    return <DashLoader message={loadingMessage} />;
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper
        title="Blogs Page"
        onSubmit={methods.handleSubmit(updateBlog)}
        loading={
          (loadingMessage === "Updating" && loading) ||
          methods.formState.isSubmitting
        }
      >
        <Section className="flex flex-col gap-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {/* Field array section */}
            <div className="absolute top-[17%] md:top-[16%] right-8 md:right-auto lg:top-[21%]  lg:left-[17%] z-40">
              <AddButton
                onClick={async () => {
                  await handleCreate(newBlogData);
                }}
                content="Create Blog"
                className={"gap-2 !p-0.5 md:!py-2 md:!px-2"}
              />
            </div>

            {/* Field Array */}
            <div className="w-full col-span-1 md:col-span-2 flex flex-col gap-8">
              {blogFields.map((blog, blogIdx) => {
                return (
                  <div
                    key={blog.id}
                    className="flex flex-col gap-4 w-full self-center md:self-start"
                  >
                    <SectionHeading
                      content={`Blog ${blogIdx + 1}`}
                      className="bg-slate-700 text-white"
                    />
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 ">
                      {blogFieldArr.map((entity, entityIdx) => {
                        return (
                          <InputComponent key={entityIdx} className="">
                            <Label htmlFor={entity.id} label={entity.label} />
                            {entity.type === "text" && (
                              <InputField
                                type={entity.type}
                                name={`blogs[${blogIdx}].${entity.name}`}
                                id={entity.id}
                                placeholder={entity.placeholder}
                                register={register(
                                  `blogs[${blogIdx}].${entity.name}`
                                )}
                              />
                            )}
                          </InputComponent>
                        );
                      })}

                      {blogIdx > 0 && (
                        <RemoveButton
                          className={"self-end"}
                          onClick={() => removeBlog(blogIdx)}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Section>
      </FormWrapper>
    </FormProvider>
  );
}

export default BlogPage;
