import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { handleResponse } from "../../../../utils/handleResponse";
import DashLoader from "../../../../components/global/dash-loader";
import { roleSlice } from "../../../../redux/slice/role-slice";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

function RolesV() {
  const loading = useSelector((state) => state.rolePage.loading);
  const error = useSelector((state) => state.rolePage.error);
  const roleData = useSelector((state) => state.rolePage.data);

  const { makeRequest: getRolesData } = useRequest("GET", {}, roleSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getRolesData("/career/role");

      handleResponse(
        response,
        statusCode,
        error,
        roleData,
        "Successfully fetched!"
      );
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !roleData) return <DashLoader message="Fetching data..." />;
  return (
    <ViewsUIWrapper title={"Role Section (Careers Page)"}>
      {roleData && (
        <Section className="flex flex-col gap-6">
          <div className="grid grid-cols-1 justify-items-center md:justify-items-start gap-8">
            {roleData?.map((info, infoIdx) => {
              return (
                <div key={infoIdx} className="flex flex-col gap-3">
                  <SectionHeading
                    content={`Role ${infoIdx + 1}`}
                    className="text-[15px]"
                  />
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                    <ViewsCard content={info?.title} label={"Title"} />
                    <ViewsCard content={info?.body} label={"Body"} />

                    <ViewsCard content={info?.paragraph} label={"Paragraph"} />
                    <ViewsCard content={info?.btn} label={"Button"} />

                    <ViewsCard content={info?.detials} label={"Details"} />

                    <ViewsCard content={info?.link} label={"Link"} />
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
      )}
    </ViewsUIWrapper>
  );
}

export default RolesV;
