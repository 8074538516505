import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { blogSlice } from "../../../../redux/slice/blog-slice";
import { toast } from "react-toastify";
import DashLoader from "../../../../components/global/dash-loader";
import PropTypes from "prop-types";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import BlogsViewCard from "../../../../components/dashboard/blogsViewCard";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

function BlogSection({ blogData }) {
  return (
    <Section className="flex sm:flex-shrink-0 flex-col md:grid md:grid-cols-2 lg:grid-cols-3 items-center justify-center md:justify-between flex-wrap gap-3 md:gap-8">
      {blogData.map((blog, blogIdx) => {
        return (
          <div
            key={blogIdx}
            className="flex flex-col gap-5 w-fit h-fit items-center rounded-lg"
          >
            {/* Blogs Header */}
            <SectionHeading content={`Blog ${blogIdx + 1}`} className="" />
            <BlogsViewCard
              title={blog?.title}
              url={blog?.url}
              author={blog?.author}
              readTime={blog?.readTime}
              image={blog?.image}
              id={blog?._id}
              isPublished={blog?.isPublished}
              alt={`Blog img ${blogIdx + 1}`}
            />
          </div>
        );
      })}
    </Section>
  );
}

BlogSection.propTypes = {
  blogData: PropTypes.array.isRequired,
};

function BlogV() {
  const loading = useSelector((state) => state.blogPage.loading);
  const error = useSelector((state) => state.blogPage.error);
  const blogData = useSelector((state) => state.blogPage.data);

  const { makeRequest: getBlogData } = useRequest("GET", {}, blogSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getBlogData("/blog");
      if (response?.ok && statusCode === 200) {
        toast.success("Successfully fetched");
        return;
      }
      if (!response?.ok && statusCode === 400) {
        toast.error(`Unsuccessful: Bad request`);
        return;
      }
      if (!response?.ok && statusCode === 401) {
        toast.error(`Unauthorized...`);
        return;
      }
      if (!response?.ok && statusCode === 500) {
        toast.error(`Internal Server Error...Please try again`);
        return;
      }
      if (error && statusCode === 0 && blogData === null) {
        toast.error(error);
        return;
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !blogData) return <DashLoader message="Loading..." />;
  return (
    <ViewsUIWrapper title="Blogs Page">
      {blogData && (
        <div className="flex flex-col gap-8">
          <BlogSection blogData={blogData} />
        </div>
      )}
    </ViewsUIWrapper>
  );
}

export default BlogV;
