import React, { useEffect } from "react";
import ViewsCard from "../../../../components/dashboard/viewsCard";
import Section from "../../../../components/dashboard/section";
import SectionHeading from "../../../../components/dashboard/sectionHeading";
import PropTypes from "prop-types";
import { replaceObjectKeys } from "../../../../utils/utilsFunction";
import { useSelector } from "react-redux";
import useRequest from "../../../../components/hook/use-request";
import { homeSlice } from "../../../../redux/slice/home-slice";
import DashLoader from "../../../../components/global/dash-loader";
import { handleResponse } from "../../../../utils/handleResponse";
import ViewsUIWrapper from "../../../../components/dashboard/viewsUIWrapper";

// Subsections
function HeroSection({ heroData }) {
  const newKeys = ["Label", "Title", "Paragraph", "Contact Button"];

  const newHeroData = replaceObjectKeys(heroData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Hero Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newHeroData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

HeroSection.propTypes = {
  heroData: PropTypes.object.isRequired,
};

function AboutSection({ aboutData }) {
  const newKeys = ["Body", "Sub-Body", "Paragraph", "Learn More Button"];

  const newSectionData = replaceObjectKeys(aboutData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="About Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
AboutSection.propTypes = {
  aboutData: PropTypes.object.isRequired,
};

function ServicesSection({ servicesData }) {
  const newKeys = ["Body", "Paragraph", "Services"];

  const newServicesData = replaceObjectKeys(servicesData, newKeys);

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Services Section" />
      <div className="w-full flex flex-col md:flex-wrap items-center md:items-start gap-5">
        <ViewsCard content={newServicesData.Body} />
        <ViewsCard content={newServicesData.Paragraph} />
        {/* Services */}
        <div className="flex flex-col gap-3 font-semibold col-span-3 w-full">
          <SectionHeading content="Services" className="self-center" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
            {newServicesData.Services?.map((service, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-col gap-3 items-center md:items-start"
                >
                  <h3>{service.title}</h3>
                  <div className="flex gap-3 flex-col md:flex-row items-center md:items-start">
                    <ViewsCard
                      label="Title"
                      content={service?.title}
                      className="self-start"
                    />
                    <ViewsCard label="Body" content={service?.body} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
}

ServicesSection.propTypes = {
  servicesData: PropTypes.object.isRequired,
};

function TalkToUsSection({ sectionData }) {
  const newKeys = ["Title", "Talk To us Button"];

  const newSectionData = replaceObjectKeys(sectionData, newKeys);
  // console.log("Rendered hero");

  return (
    <Section className="flex flex-col gap-6">
      <SectionHeading content="Talk To Us Section" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center md:justify-items-start gap-4">
        {Object.entries(newSectionData).map(([element, desc]) => {
          return (
            <div key={element} className="flex flex-col gap-3 font-semibold">
              <h3>{element}</h3>
              <ViewsCard content={desc} />
            </div>
          );
        })}
      </div>
    </Section>
  );
}
TalkToUsSection.propTypes = {
  sectionData: PropTypes.object.isRequired,
};

// Main component
const HomeV = () => {
  const loading = useSelector((state) => state.homePage.loading);
  const error = useSelector((state) => state.homePage.error);
  const homeData = useSelector((state) => state.homePage.data);

  const { makeRequest: getHomeData } = useRequest("GET", {}, homeSlice);

  useEffect(() => {
    const getData = async () => {
      const [, statusCode, response] = await getHomeData("/home");

      handleResponse(
        response,
        statusCode,
        error,
        homeData,
        "Successfully fetched!"
      );
    };

    // console.log("Function call...");

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !homeData) return <DashLoader message="Loading..." />;

  return (
    <ViewsUIWrapper title={"Home Page"}>
      {homeData && (
        <div className="flex flex-col gap-8">
          <HeroSection heroData={homeData?.hero} />
          <AboutSection aboutData={homeData?.about} />
          <ServicesSection servicesData={homeData?.services} />
          <TalkToUsSection sectionData={homeData?.section} />
        </div>
      )}
    </ViewsUIWrapper>
  );
};

export default HomeV;
